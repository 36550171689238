@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "General Sans";
  src: url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Regular.woff2")
      format("woff2"),
    url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Medium.woff2")
      format("woff2"),
    url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "General Sans";
  src: url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Semibold.woff2")
      format("woff2"),
    url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "General Sans";
  src: url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Bold.woff2")
      format("woff2"),
    url("/public/fonts/GeneralSans_Complete/Fonts/WEB/fonts/GeneralSans-Bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
}

@layer base {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: transparent !important;
    transition: background 5000s ease-in;
  }
  /* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
} */

  [type="text"],
  [type="email"],
  [type="url"],
  [type="password"],
  [type="number"],
  [type="date"],
  [type="datetime-local"],
  [type="month"],
  [type="search"],
  [type="tel"],
  [type="time"],
  [type="week"],
  [multiple],
  textarea,
  select {
    @apply transition duration-200 px-4 py-2 border text-xs lg:text-sm xl:text-base w-full border-zinc-300 rounded-lg  h-[50px] placeholder-grey-60 focus:outline-none focus:ring-0 focus:border-zinc-400;
  }

  select {
    @apply appearance-none relative pr-10;
  }

  input[type="radio"] {
    @apply text-theme-darker ring-theme-darker !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 16px;
  }

  /* * {
    font-style: normal;
    font-size: 14px;
  }
  @screen xl {
    * {
      font-style: normal;
      font-size: 16px;
    }
  } */
  svg {
    font-size: inherit;
    color: inherit;
  }
  b {
    @apply font-semibold text-black/90;
  }

  html {
    scroll-behavior: smooth;
  }
  body {
    @apply overflow-x-hidden   text-sm xl:text-base h-full;
  }
  h1,
  .h1 {
    @apply text-black font-poppins text-[40px] font-bold tablet:text-[50px] md:text-[56px] xl:text-[72px] xxl:text-[76px];
    line-height: 1.25;
  }
  h2,
  .h2 {
    @apply text-black font-poppins text-[28px] tablet:text-[48px] font-bold xl:text-[48px] xxl:text-[54px];
    line-height: 1.25;
  }
  h3,
  .h3 {
    @apply text-black font-poppins text-[28px] font-bold tablet:text-[28px] md:text-[30px] xl:text-[40px] xxl:text-[45px];
    line-height: 1.25;
  }
  h4,
  .h4 {
    @apply text-black font-poppins text-[21px] font-bold tablet:text-[20px] md:text-[22px] xl:text-[28px] xxl:text-[36px];
    line-height: 1.25;
  }
  h5,
  .h5 {
    @apply text-black font-poppins text-[18px] font-bold  tablet:text-[18px] xxl:text-[25px];
    line-height: 1.25;
  }
  h6,
  .h6 {
    @apply text-black font-poppins text-[16px] font-bold xxl:text-[24px];
    line-height: 1.25;
  }
  p {
    @apply text-[16px] xxl:text-[18px] font-normal text-theme-text;
    line-height: 1.2;
  }
  a {
    text-decoration: none;
  }
  ::-webkit-scrollbar {
    @apply w-2 h-2 bg-gray-300 rounded-md;
  }
  ::-webkit-scrollbar-thumb {
    @apply w-2 h-2 bg-gray-600 rounded-md;
  }
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-700;
  }

  body::-webkit-scrollbar {
    @apply w-3 h-3 bg-gray-300 rounded-none;
  }
  body::-webkit-scrollbar-thumb {
    @apply w-3 h-3 bg-gray-600 rounded-none;
  }
  body::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-700;
  }

  /* .container {
    @apply mx-auto xxl:max-w-[1550px] tablet:max-w-[700px] md:max-w-[900px] lg:max-w-[1100px] xl:max-w-[1280px] px-6 tablet:px-0 !important;
  } */
  .referral-users {
    height: min(55vh, 500px);
    overflow-y: auto;
  }
  .referral-users::-webkit-scrollbar {
    border-radius: 8px;
    width: 8px;
    @apply bg-zinc-100;
  }
  .referral-users::-webkit-scrollbar-thumb {
    border-radius: 8px;
    width: 8px;
    @apply bg-zinc-200;
  }
}

@layer components {
  .a-center {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2;
  }
  .ax-center {
    @apply absolute  left-1/2 -translate-x-1/2;
  }
  .ay-center {
    @apply absolute top-1/2 -translate-y-1/2;
  }
  .flex-center {
    @apply flex justify-center items-center;
  }
  .btn {
    @apply h-[40px] xl:h-[46px] cursor-pointer  font-medium text-xs md:text-sm xl:text-[16px] rounded-md  px-4 xl:px-[28px] duration-150  flex-center active:scale-[0.99];
  }
  .btn-sm {
    @apply h-[32px] lg:h-[38px] px-[14px] xl:px-4 text-xs;
  }
  .btn-sm-width {
    @apply w-[92px];
  }
  .btn-lg-width {
    @apply min-w-[192px];
  }
  .settings input {
    @apply h-[32px] lg:h-[38px];
  }
  .btn-primary {
    @apply bg-theme-darker text-white border-2 border-theme-darker;
  }
  .btn-danger {
    @apply bg-red-dark text-white;
  }
  .btn-outline-primary {
    @apply btn  rounded-md border-2 border-theme-darker text-theme-darker  flex-center hover:bg-theme-darker hover:text-white transition-all duration-300;
  }
  .btn-outline-danger {
    @apply btn  rounded-md border-2 border-red-dark text-red-dark   hover:bg-red-dark  px-[20px]  flex-center  hover:text-white transition-all duration-300;
  }
  .form-select-custom {
    @apply relative;
  }
  .data-grid .MuiDataGrid-toolbarContainer {
    @apply p-3 !important;
  }
  .data-grid .MuiDataGrid-root,
  .data-grid .MuiDataGrid-toolbarContainer,
  .data-grid .MuiDataGrid-main,
  .data-grid .MuiDataGrid-footerContainer {
    @apply w-full !important;
  }

  .data-grid .MuiDataGrid-cellContent,
  .data-grid .MuiButton-text.MuiButton-textPrimary,
  .data-grid .MuiSvgIcon-root,
  .data-grid .MuiSelect-select.MuiTablePagination-select,
  .data-grid .MuiTablePagination-selectLabel,
  .data-grid .MuiTablePagination-displayedRows,
  .data-grid-fs {
    @apply text-black text-xs lg:text-sm  !important;
  }

  .data-grid .MuiDataGrid-columnHeader:focus-within {
    @apply outline-none !important;
  }
  .data-grid .MuiDataGrid-virtualScroller {
    overflow: unset !important;
    overflow-y: auto !important;
    @apply overflow-x-hidden !important;
  }
  /* new for user purchase request */
  .data-grid .MuiDataGrid-virtualScroller {
    overflow-x: auto !important;
  }

  .data-grid .MuiDataGrid-columnHeaderTitle {
    @apply text-xs lg:text-sm  text-grey-190 font-bold font-poppins !important;
  }

  .data-grid * {
    @apply font-poppins !important;
  }

  .data-grid select {
    @apply text-black !important;
  }
  .form-eror {
    @apply text-red-700  mt-2 text-sm font-semibold;
  }
}
@layer utilities {
  .para {
    @apply text-[16px] xxl:text-[18px] font-medium;
  }
  .subtitle {
    @apply text-[16px] xxl:text-[18px] font-medium;
  }
  .caption {
    @apply text-[16px] xxl:text-[18px] font-medium;
  }
  .shadow4 {
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1),
      0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
  }
  .shadow8 {
    box-shadow: 0px 0.6px 1.8px rgba(0, 0, 0, 0.1),
      0px 3.2px 7.2px rgba(0, 0, 0, 0.13);
  }
  .shadow16 {
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
      0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  }
  .shadow64 {
    box-shadow: 0px 4.8px 14.4px rgba(0, 0, 0, 0.18),
      0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
  }
  label {
    font-size: 14px;
  }
}
input[readonly] {
  @apply bg-zinc-200 text-zinc-500;
}

/* New */
li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding: 0px 10px;
  margin-top: 5px;
}

.red-btn {
  @apply bg-red-600 font-semibold text-white px-6 py-3 rounded-lg;
}

.bg-common {
  background: linear-gradient(360deg, #ff0000 0%, #000000 50%, #000000 100%);
}
.border-red {
  border: 2px solid;
  border-image-source: linear-gradient(
    90deg,
    #ee0000 0%,
    #ffffff 50%,
    #9d0303 100%
  );
  border-image-slice: 1;
}

.calculate-border-red {
  border-width: 0.79px 3.97px 3.97px 0.79px;
  border-style: solid;
  border-color: var(--primary-color, #ee0000);
}
.calculate-border-gray {
  border: 0.79px solid var(--Secondary-1, #ffffff);
}

.input-red-br {
  border-width: 0.67px, 0.67px, 0px, 0px;

  border-style: solid;

  border-color: #ffffff;
}
.react-tel-input .selected-flag:hover {
  background: none !important;
}
.react-tel-input .selected-flag:focus {
  background: none !important;
}
/* .react-tel-input .selected-flag{
  background: #786e6e4d !important;
} */
.react-tel-input .flag-dropdown.open .selected-flag {
  background: none !important;
}
.text-input:-webkit-autofill {
  /* -webkit-box-shadow: 0 0 0 1000px #0F0F0F inset; */
  -webkit-text-fill-color: white;
}

/* .text-input:-webkit-autofill:focus {
  border: 1px solid #EE0000;
} */
.bg-gradient-red {
  background: linear-gradient(90deg, #ee0000 0%, #9d0303 100%);
}
.btn-gradient {
  background: linear-gradient(180deg, #ee0000 0%, #000000 100%);
}
.bg-gradient-1 {
  background: linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%);
}
.bg-gradient-2 {
  background: linear-gradient(180deg, #ff0000 2%, #000000 96%, #000000 117%);
}

input::-webkit-input-placeholder {
  color: #000; /* Placeholder text color */
}

input::selection {
  background: #b3d4fc; /* Background color for selected text */
  color: #000; /* Text color for selected text */
}

input::-webkit-selection {
  background: #b3d4fc; /* Background color for selected text (WebKit specific) */
  color: #000; /* Text color for selected text */
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(390deg);
  }
}

.rotate-animation {
  animation: rotateAnimation 1s ease-in-out;
}

@keyframes fillBackground {
  0% {
    background-size: 50% 100%;
  }
  100% {
    background-size: 100% 100%;
  }
}

.animate-fill {
  animation: fillBackground 0.5s forwards;
  background: linear-gradient(270deg, #ee0000 0%, #000000 100%);
  background-repeat: no-repeat;
  background-size: 50% 100%;
}

@keyframes retractBackground {
  0% {
    background-size: 100% 100%;
  }
  100% {
    background-size: 100% 100%;
  }
}

.animate-retract {
  animation: retractBackground 0.5s forwards; /* Apply retract animation */
  background: linear-gradient(
    270deg,
    #ee0000 0%,
    #000000 100%
  ); /* Gradient fill */
  background-repeat: no-repeat; /* No repeating */
}
.gradient-background {
  background: linear-gradient(
    270deg,
    #ee0000 0%,
    #000000 100%
  ); /* Gradient fill */
}
.text-color {
  background: linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tlb {
  position: absolute;

  animation: moveFromTopLeftToBottom 8s ease-in-out forwards; /* Increased duration */
}

@keyframes moveFromTopLeftToBottom {
  20% {
    top: 0;
    left: -518px;
    transform: translateX(-30px);
  }
  40% {
    top: 0;
    left: -463px;
    transform: translateX(-30px);
  }
  60% {
    top: -116px;
    left: -353px;
    transform: translateX(-30px);
  }
  80% {
    top: -116px;
    left: -122px;
    transform: translateX(0);
  }
  100% {
    bottom: 0px;
    left: 72px;
    transform: translateX(0);
  }
}
.stsearch-box {
  background: #786e6e4d !important;
  padding: 7px !important;
  font-family: Poppins, sans-serif !important;
}
.stsearch-box input:focus {
  border: none !important;
  font-family: Poppins, sans-serif !important;
}
.stsearch-box input {
  outline: none !important;
  background: none !important;
  border: none !important;
  color: white !important;
  font-size: 14px !important;
  font-family: Poppins, sans-serif !important;
}
.stsearch-box input::placeholder {
  color: #fdfdfdbd !important;
}
.stdropdown-menu {
  background: linear-gradient(270deg, #ee0000 0%, #000000 100%) !important;
  font-family: Poppins, sans-serif !important;
}
.stdropdown-container {
  border-radius: 7px !important;
  font-family: Poppins, sans-serif !important;
}
.stdropdown-container:focus-within {
  border: 1px solid red !important;
  color: white !important;
}
@media only screen and (max-width: 600px) {
  .stsearch-box {
    background: #786e6e4d !important;
    padding: 10px !important;
    font-family: Poppins, sans-serif !important;
  }
  .stsearch-box input {
    font-size: 12px !important;
  }
}
.coming-soon-bg {
  background: rgba(44, 105, 135, 0.5);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px);
  border: 1px solid #2c698740;
  @apply absolute left-[0px] lg:left-[0] top-[0] bottom-0 overflow-hidden z-[99999];
}
.side-open .coming-soon-bg {
  @apply left-[0] lg:left-[0];
}
@media (max-width: 767px) {
  .side-open .coming-soon-bg {
    @apply left-[0] lg:left-[0] pl-[2.5rem];
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .side-open .coming-soon-bg {
    @apply left-[0] lg:left-[0] pl-10;
  }
}

.no-focus-ring {
  outline: none !important;
}
.no-focus-ring {
  -webkit-tap-highlight-color: transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.data-grid select {
  color: white !important;
}

.Toastify__toast-container--top-left,
.Toastify__toast-container--top-center,
.Toastify__toast-container--top-right {
  z-index: 99999 !important;
}

.bg-captcha-img {
  /* background-image: url('../public/images/captcha/captcha.png'); */
  width: 250px;
  height: 60px;
}

input[type="file"]::file-selector-button {
  border: none;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background: linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%);
  transition: 1s;
  color: white;
}

/* input[type=file]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
} */

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}
.hover-effect {
  position: relative;
  overflow: hidden;
  z-index: 1; /* Ensure the main content is above the pseudo-element */
}

.hover-effect::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: #ee0000; /* Your desired hover color */
  transition: all 0.3s ease;
  z-index: 0; /* Keep this below the content */
}

.hover-effect:hover::after {
  top: 0;
  left: 0;
}
.animate-right-to-right {
  visibility: hidden;
  position: relative;
}
.hover-effect:hover .animate-right-to-right {
  visibility: visible;
  animation: slideRight 0.5s ease-out forwards;
}
.hover-effect > * {
  position: relative;
  z-index: 1;
}
.para-1 {
  @apply mb-5 text-16 leading-[25px] text-white;
}
.head-1 {
  @apply mb-3 text-20 font-semibold leading-[38px] text-white;
}
.para-1-span {
  @apply text-16 leading-[25px] text-primary-200;
}
.para-2 {
  @apply mb-3 text-16 leading-[25px] text-white;
}
.head-2 {
  @apply mb-1 text-20 font-semibold leading-6 text-white wlg:leading-[38px];
}
.head3 {
  @apply mb-3 text-16 font-bold leading-5 text-primary-200 wlg:text-18 wlg:leading-[26px];
}
.solution-head {
  @apply mb-5 text-[35px] font-bold leading-[50px] text-white wlg:text-[50px] wlg:leading-[70px] wxl:text-[60px];
}
.solution-para {
  @apply text-18 font-medium leading-[34px] text-white wmd:text-20 wlg:text-22;
}
.p-para-1 {
  @apply mb-5 text-16 leading-[25px] text-[#555555];
}
.p-head-1 {
  @apply mb-3 text-20 font-semibold leading-[38px] text-[#333333];
}
.p-para-1-span {
  @apply text-16 leading-[25px] text-primary-200;
}
.p-para-2 {
  @apply mb-3 text-16 leading-[25px] text-[#464646];
}
.p-head-2 {
  @apply mb-1 text-20 font-semibold leading-6 text-[#333333] lg:leading-[38px];
}
.p-head3 {
  @apply mb-3 text-16 font-bold leading-5 text-primary-200 lg:text-18 lg:leading-[26px];
}
.p-solution-head {
  @apply mb-5 text-[35px] font-bold leading-[50px] text-white lg:text-[50px] lg:leading-[70px] xl:text-[60px];
}
.p-solution-para {
  @apply text-18 font-medium leading-[34px] text-white md:text-20 lg:text-22;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.animate-marquee {
  animation: marquee 10s linear infinite;
}

/* Restrict autofill style to specific input fields */
.custom-phone-input:-webkit-autofill,
.custom-phone-input:-webkit-autofill:hover,
.custom-phone-input:-webkit-autofill:focus,
.custom-phone-input:-webkit-autofill:active,
.custom-password-input:-webkit-autofill,
.custom-password-input:-webkit-autofill:hover,
.custom-password-input:-webkit-autofill:focus,
.custom-password-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px #786e6e4d inset !important; /* Maintain background */
  -webkit-text-fill-color: white !important; /* Keep text color white */
  color: white !important;
  transition: background-color 5000s ease-in-out 0s; /* Prevent quick autofill effect */
}

.special-label {
  display: none !important;
}

.custom-datepicker-popper {
  z-index: 9999 !important;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}

.generalFont {
  font-family: "General Sans";
}
.react {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  margin-top: -125px;
  margin-left: -125px;
  cursor: "pointer";
}

#notif {
  visibility: hidden;
  position: fixed;
  color: white;
  border-radius: 10px;
  top: 0;
  right: 0;
  transform: translate(-15px, 15px);
  border-radius: 10px;
  overflow: hidden;
}

.notif-text-icon {
  align-items: stretch;
  box-shadow: 0 1px 1px rgb(10 10 10 / 10%);
  display: flex;
  max-width: 250px;
}

.notif-message {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-weight: 700;
  padding: 15px 25px;
}

#notif.show {
  visibility: visible;
  animation: show 0.5s forwards;
}

@keyframes show {
  0% {
    transform: translate(-15px, 15px) scale(0);
  }

  50% {
    transform: translate(-15px, 15px) scale(1.2);
  }

  70% {
    transform: translate(-15px, 15px) scale(0.9);
  }

  100% {
    transform: translate(-15px, 15px) scale(1);
  }
}

.home-top {
  background-image: url(/public/images/home/topBg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.about-1 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff3f3 100%);
}
.ab-center {
  z-index: 1;
}
.a-top {
  animation: top 3s both infinite;
}

.a-lock {
  animation: lock 3s both infinite;
}
.a-bottomBox {
  animation: btm 3s both infinite;
}
.a-atoms {
  animation: top 3s both infinite;
}
.a-top-new {
  animation: topNew 3s both infinite;
}
@keyframes topNew {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, -102px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes top {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(0px, 30px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes lock {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(30px, -30px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes btm {
  0% {
    transform: translate(0px, 0px);
  }

  50% {
    transform: translate(-30px, -30px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.top-grid-bg {
  animation: top-grid 10s linear infinite;
}

@keyframes top-grid {
  0% {
    transform: scale(1);
  }

  /* 50% {
    transform: scale(1.2);
  } */

  100% {
    transform: scale(1.2);
  }
}

.blocks-bg {
  animation: blocks 5s linear infinite;
}

@keyframes blocks {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.redShadow {
  box-shadow: 8px 8px 0px #7c7c7c;
  transition: box-shadow 0.3s ease;
}
.redShadow:hover {
  box-shadow: 8px 8px 0px #ee0000;
}

.whiteShadow:hover {
  box-shadow: 3px 3px 0px #ffffff;
}

/* 'white-shadow': '', // Right 3px, Bottom 3px, No blur
        'red-shadow': '8px 8px 0px #EE0000', // Right 8px, Bottom 8px, No blur
        'red-b-shadow': '0px 8px 0px  #EE0000', // Right 8px, Bottom 8px, No blur
      */
.hover-effect {
  position: relative;
  overflow: hidden;
  z-index: 1; /* Ensure the main content is above the pseudo-element */
}

.hover-effect::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: #ee0000; /* Your desired hover color */
  transition: all 0.3s ease;
  z-index: 0; /* Keep this below the content */
}

.hover-effect:hover::after {
  top: 0;
  left: 0;
}
/* .hover-effect::after {
  content: '';
  position: absolute;
  bottom: -50px; 
  right: -50px;
  width: 0;    
  height: 0;      
  background-color: #ee0000; 
  transform: rotate(45deg); 
  transition: all 0.6s ease-in-out; 
  z-index: 0; 
}

.hover-effect:hover::after {
  width: 300%;  
  height: 300%;  
  bottom: 0;     
  right: 0;
} */
.animate-right-to-right {
  @apply visible relative lg:invisible;
}

.hover-effect:hover .animate-right-to-right {
  visibility: visible;
  animation: slideRight 0.5s ease-out forwards;
}
.hover-effect > * {
  position: relative;
  z-index: 1;
}

.bg-contact {
  background-image: url("/public/images/contactBg.png");
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  min-height: 800px;
}
@keyframes slideRight {
  0% {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  100% {
    transform: translateX(0); /* End at the original position */
  }
}

@media (max-width: 768px) {
  .bg-contact {
    background-image: none;
    min-height: 500px;
  }
}

.shapes {
  width: 44.8px;
  height: 44.8px;
  color: #ed0000;
  background: linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0), linear-gradient(currentColor 0 0),
    linear-gradient(currentColor 0 0);
  background-size: 23.4px 23.4px;
  background-repeat: no-repeat;
  animation: shapes-53h9rpmd 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes shapes-53h9rpmd {
  0% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  }

  33% {
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    width: 67.2px;
    height: 67.2px;
  }

  66% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
    width: 67.2px;
    height: 67.2px;
  }

  100% {
    background-position: 100% 0, 100% 100%, 0 100%, 0 0;
  }
}

.parallax {
  background-image: url("/public/images/blockBg.png");
  min-height: 1000px;
  background-attachment: fixed;
  background-position: center;
  top: 100px;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .parallax {
    background-image: none;
    min-height: auto;
  }
}
.no-download {
  pointer-events: none;
}
.border-red {
  border: 2px solid;
  border-image-source: linear-gradient(
    90deg,
    #ee0000 0%,
    #ffffff 50%,
    #9d0303 100%
  );
  border-image-slice: 1;
}
